import React from "react";
import TestimonialIconTwo from "../../assets/img/testimonial/qt-icon.png";
import Slider from "react-slick";


function Testimonialslider() {
  
  const data = [
    {
      id: 1,
      img: "assets/img/testimonial/testi_avatar.png",
      img2: "assets/img/testimonial/review-icon.png",
      name: "sucharita c",
      para: "He is by far the best dentist I have come across in Bhubaneswar. Well behaved, extremely talented , and conducts almost painless procedures, no unnecessary interventions. I had a wisdom tooth extraction with him , which was completely painless."
    },
    {
      id: 2,
      img: "assets/img/testimonial/testi_avatar_02.png",
      img2: "assets/img/testimonial/review-icon.png",
      name: "Bhaswati Ghosh",
      para: "We went to Dr. Sauvik and Dr. Neha for a root canal procedure and it was very nicely done. They explained everything in detail and the entire procedure and post procedure healing was issue free and pain free. Besides being excellent at their job they both have a very sunny and jolly personality which makes the entire experience wholesome. I would highly recommend them for any kind of dental procedures"
    },
    {
      id: 3,
      img: "assets/img/testimonial/testi_avatar_03.png",
      img2: "assets/img/testimonial/review-icon.png",
      name: "Anirudha Majhi",
      para: "I was very impressed with the expertise of the dentists. They were not only highly skilled but also very patient-focused. They listened carefully to my concerns and recommended the best course of treatment that was not only effective but also cost-effective. It was clear they were looking for long-term solutions to my dental needs, which I greatly appreciated."
    },
    {
      id: 4,
      img: "assets/img/testimonial/testi_avatar_04.png",
      img2: "assets/img/testimonial/review-icon.png",
      name: "Komalprit Kaur",
      para: "I had done my dental treatment from care itself and it was a great experience. The doctor's behavior was best and his hand techniques were superb. I was treated by Dr.Sauvik singha and Dr. Neha Mohanty. They treated me so well and there behaviour was nice as well. They keep me in constant care. Post operation too whenever we contact them they guide very politely. Very much greatful to them."
    },
    {
      id: 5,
      img: "assets/img/testimonial/testi_avatar_05.png",
      img2: "assets/img/testimonial/review-icon.png",
      name: "Rajashree Nayak",
      para: "The treatment planning was customised according to my needs. They considered all aspects and explained all the pros and cons before making a decision. All the doctors and other staff members have been very polite and warm throughout the process. The behaviour has been professional yet very charming. The appointments were tailored according to my needs and the response of the team to any emergent need wasimmediate. Most importantly, they take good care to avoid any post-op complications. And they always advise a rational and economic approach"
    },
    {
      id: 6,
      img: "assets/img/testimonial/testi_avatar_06.png",
      img2: "assets/img/testimonial/review-icon.png",
      name: "kesav rathi",
      para: "Wonderful experience and quality treatment with proper guidance and limited medication. Treatment was done during covid time and I m very much satisfied.."
    },
  ];

  const satting = {
    dots: true,
    infinite: true,
    arrows: false,
    prevArrow:
      '<button type="button" className="slick-prev"><i className="far fa-chevron-left"></i></button>',
    nextArrow:
      '<button type="button" className="slick-next"><i className="far fa-chevron-right"></i></button>',
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="testimonial-area pb-115 p-relative fix" style={{ backgroundColor: "#ffffff" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center mb-50 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>Testimonial</h5>
                <h2>What Our Patients Says</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <Slider style={{ margin: '0px !important' }} className="testimonial-active" {...satting}>
                {data.map((item) => (
                  <div className="single-testimonial m-0" key={item.id}>
                    <div className="testi-author">
                      <img src={item.img} alt="img" />
                    </div>
                    <div className="review mb-30">
                      <img src={item.img2} alt="img" />
                    </div>
                    <h4>{item.name}</h4>
                    <p>
                      {item.para}
                    </p>
                    <div className="qt-img">
                      <img src={TestimonialIconTwo} alt="img" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section> 
    </>
  );
}

export default Testimonialslider;
