import React from 'react'
import GallerySec from '../PagesMen/Gallery/Main'
import GallerySecond from '../PagesMen/Gallery/GallerySecond'
import GalleryThird from '../PagesMen/Gallery/GalleryThird'

function Gallarymen() {
  return (
    <>
        <GallerySec/>
        <GallerySecond/>
        <GalleryThird/>
        
    </>
  )
}

export default Gallarymen