import React, { useEffect } from "react";
import { Route, Routes, useLocation, HashRouter } from "react-router-dom";
import Header from "../components/Header/Main";
import Footer from "../components/Footer/Main";
import Home from "../components/Home/Main";
import About from "../components/About-Us/Main";
import Contact from "../components/Contact-Us/Main";
import Blog from "../components/Blog/Main";
import Service from "../components/Service/Main";
import Servicedeatils from "../components/Servicesdeatils/Main";
import Gallery from "../components/Pages/Gallarymen";
import Faq from "../components/PagesMen/Faq/Main";
import Team from "../components/PagesMen/Team/Main";

function Index() {
  //   const location = useLocation();
  //   const path = location.pathname
  //   useEffect(() => {
  //     window.scroll(0, 0)
  // }, [path]);

  return (
    <>
      <HashRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/service" element={<Service />} />
          <Route path="/singleservice" element={<Servicedeatils />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/team" element={<Team />} />
        </Routes>
        <Footer />
      </HashRouter>
    </>
  );
}

export default Index;
