import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function MobileHeader() {
  const [mobile, setmobile] = useState(false);
  // const [home, setHome] = useState(false);
  const [Services, setServices] = useState(false);
  const [Pages, setPages] = useState(false);
  // const [Blog, setBlog] = useState(false);
  const menuRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setmobile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <div className="mobile-menu mean-container" ref={menuRef}>
        <div className="mean-bar">
          <Link
            to="#"
            onClick={() => setmobile(!mobile)}
            className={`meanmenu-reveal ${mobile && "meanclose"}`}
            style={{
              right: "0px",
              left: "auto",
              textAlign: "center",
              textIndent: "0px",
              fontSize: "18px",
            }}
          >
            {mobile ? (
              "X"
            ) : (
              <span>
                <span>
                  <span></span>
                </span>
              </span>
            )}
          </Link>
          {mobile && (
            <nav className="mean-nav">
              <ul style={{ display: "block" }}>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li className="has-sub">
                  <Link to="/services">Services</Link>
                  {Services && (
                    <ul style={{ display: "block" }}>
                      <li>
                        <Link to="/service"> Root Canal</Link>
                      </li>
                      <li>
                        <Link to="/service">Dental Implants</Link>
                      </li>
                      <li>
                        <Link to="/service"> Tooth Filling</Link>
                      </li>
                      <li>
                        <Link to="/service">Dental X-ray</Link>
                      </li>
                      <li>
                        <Link to="/service">Oral Surger</Link>
                      </li>
                      <li>
                        <Link to="/service">Veneer & Crown</Link>
                      </li>
                      <li>
                        <Link to="/service">Services</Link>
                      </li>
                    </ul>
                  )}
                  <Link
                    className={`mean-expand ${mobile && "mean-clicked"}`}
                    to="#"
                    onClick={() => {
                      setServices(!Services);
                    }}
                    style={{ fontSize: 18 }}
                  >
                    {Services ? "-" : "+"}
                  </Link>
                </li>
                <li className="has-sub">
                  <Link to="#">Pages</Link>
                  {Pages && (
                    <ul style={{ display: "block" }}>
                      <li>
                        <Link to="/gallery">Gallery</Link>
                      </li>

                      <li>
                        <Link to="/faq">Faq</Link>
                      </li>
                      <li>
                        <Link to="/team">Team</Link>
                      </li>
                    </ul>
                  )}
                  <Link
                    className={`mean-expand ${Pages && "mean-clicked"}`}
                    to="#"
                    style={{ fontSize: 18 }}
                    onClick={() => {
                      setPages(!Pages);
                    }}
                  >
                    {Pages ? "-" : "+"}
                  </Link>
                </li>
                <li className="mean-last">
                  <Link to="/blog">Blog</Link>
                </li>
                <li className="mean-last">
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
