import React, { useState } from 'react';
import Breadcrumb from '../../Menhome/breadcrumb';
import FsLightbox from 'fslightbox-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const imageData = [
    {
      id: 1,
      src: require('../../../assets/img/gallery/protfolio-img01.png'),
      alt: 'Image 1',
    },
    {
      id: 2,
      src: require('../../../assets/img/gallery/protfolio-img02.png'),
      alt: 'Image 2',
    },
    {
      id: 3,
      src: require('../../../assets/img/gallery/protfolio-img03.png'),
      alt: 'Image 3',
    },
    {
      id: 4,
      src: require('../../../assets/img/gallery/protfolio-img04.png'),
      alt: 'Image 4',
    },
    {
      id: 5,
      src: require('../../../assets/img/gallery/protfolio-img05.png'),
      alt: 'Image 5',
    },
    {
      id: 6,
      src: require('../../../assets/img/gallery/protfolio-img07.png'),
      alt: 'Image 6',
    },
  ];

function Main() {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSource(sourceIndex) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: sourceIndex + 1,
    });
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay:true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Breadcrumb subtitle="Gallery" title="Home" subtitledown="Gallery" />

      <section id="work" className="pt-105">
        <div className="container">
          <h2 className="text-center">Dental Cases</h2>
          <br />
          <div className="row d-none d-md-flex">
            {imageData.map((image, index) => (
              <div className="col-lg-4 col-md-6" key={image.id}>
                <div className="card" onClick={() => openLightboxOnSource(index)}>
                  <img className="card-img-top" src={image.src} alt={image.alt} />
                  <div className="card-body"></div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-md-none">
            <Slider {...settings}>
              {imageData.map((image, index) => (
                <div key={image.id} onClick={() => openLightboxOnSource(index)}>
                  <div className="card">
                    <img className="card-img-top" src={image.src} alt={image.alt} />
                    <div className="card-body"></div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      <FsLightbox
        toggler={lightboxController.toggler}
        sources={imageData.map((image) => image.src)}
        slide={lightboxController.slide}
      />
    </>
  );
}

export default Main;